import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import Home from "@/views/Home.vue";
import PrivilegeGroup from "@/views/User/Index.vue";
import Role from "@/views/User/Role.vue";
import User from "@/views/User/User.vue";
import PGSetting from "@/views/User/PGSetting.vue";
import RoleSetting from "@/views/User/RoleSetting.vue";
import UserSetting from "@/views/User/UserSetting.vue";
import BudgutInput from "@/views/Budget/BudgetInput/BudgutInput.vue";
import BudgutInputControlling from "@/views/Budget/BudgetInput/BudgutInputControlling.vue";
import BudgutExcel from "@/views/Budget/DownloadExcel.vue";
//budget report
import BudgetReport from "@/views/Budget/BudgetReport/index.vue";
//budget Allocation
import BudgetAllocation from "@/views/Budget/BudgetAllocation/index.vue";
import { isMobile } from "./../utils/index";
import { PageView } from "@/API/log";

// program
const ProgramDesign = () => import("@/views/Program/ProgramDesign.vue");
const Design = () => import("@/views/Program/Design.vue");
const CombinabilitySetting = () =>
  import("@/views/Program/CombinabilitySetting/Index.vue");

// SSSC
const SSSCIndex = () => import("@/views/SSSC/SSSCIndex.vue");

// RetailValidation
import { rvRouters } from "./rv";
// Claim
import { claimRouters } from "./claim";

// Checking
import { checkingRouters } from "./checking";

// payment
import { pmtRouters } from "./payment";
//systemVehicle
import { systemVehicleRouters } from "./systemRouters";
//dealerMasterData
import { dealerMasterDataRouters } from "@/router/dealerMasterData";
// closing
import { closingRouters } from "./closing";
// forecast
import { forecastRouters } from "./forecast";
// planning
import { planningRouters } from "./planning";
//vehiclelicense
import { vlRouters } from "./vl";
// setting
import { settingRouters } from "./setting";

// taskcenter
import { taskCenterRouters } from "./taskCenter";

// delay
import { delayRouters } from "./delay";

// communication
import { communicationRouters } from "./communication";

// delayCallback
import { delayCallbackRouters } from "./delayCallback";

// Sales
import { salesRepotringRouters } from "./SalesReportiong";
// guiDang
import { guiDangRouters } from "./guidang";
// SI Confirguration Report
import { reportingRouters } from "./reporting";

// localRegistration
import { localRegistrationRouters } from "./localRegistration";

import { faqRouters } from "./faq";

// FA 页面组件
const CalculatorMaintain = () =>
  import("@/views/Finance/CalculatorMaintain.vue");
const CalculatorStatus = () => import("@/views/Finance/CalculatorStatus.vue");
const FaSetting = () => import("@/views/Finance/FaSetting.vue");
const EstimatedPer = () => import("@/views/Finance/EstimatedPer.vue");
const CalculatorVersion = () => import("@/views/Finance/CalculatorVersion.vue");
const CalcelatorReopen = () => import("@/views/Finance/CalculatorReopen.vue");

//审批流页面组件
//sales role
const ProgramHome = () =>
  import("@/views/Approve/SalesRoleApprove/ProgramHome/index.vue");
const CreatePackage = () =>
  import("@/views/Approve/SalesRoleApprove/CreatePackage/index.vue");
const PackageHome = () =>
  import("@/views/Approve/SalesRoleApprove/PackageHome/index.vue");
const ReleasePackageHome = () =>
  import("@/views/Approve/SalesRoleApprove/ReleasePackageHome/index.vue");
const ReleaseSetting = () =>
  import("@/views/Approve/SalesRoleApprove/ReleaseSetting/index.vue");
//mg role
const MgPackageHome = () =>
  import("@/views/Approve/MgRoleApprove/PackageHome/index.vue");
const MgPackageDetail = () =>
  import("@/views/Approve/MgRoleApprove/PackageDetail/index.vue");
//controlling role
const ControllingProgramHome = () =>
  import("@/views/Approve/ControllingRoleApprove/ProgramHome/index.vue");
const WordingBudget = () =>
  import(
    "@/views/Approve/ControllingRoleApprove/ProgramHome/WordingBudget.vue"
  );
// F&A
const ApproveFA = () => import("@/views/Approve/SalesRoleApprove/FA/Index.vue");

// UAM 路由
const uamRouters: Array<RouteRecordRaw> = [
  {
    path: "/privilegegroup",
    name: "Privilege Group",
    component: PrivilegeGroup,
  },
  {
    path: "/pgsetting/:id",
    name: "Privilege Group Setting",
    component: PGSetting,
  },
  {
    path: "/role",
    name: "Role",
    component: Role,
  },
  {
    path: "/rolesetting/:id/:rolid",
    name: "Role Setting",
    component: RoleSetting,
  },
  {
    path: "/user",
    name: "User",
    component: User,
  },
  {
    path: "/usersetting/:id/:type/:name/:orgid/:usrtpcode",
    name: "User Setting",
    component: UserSetting,
  },
  // 审批流
  /*{
      path: '/approvalChian',
      name: 'Approval Chian',
      component: () =>
      import('@/views/User/ApprovalProcess/ApprovalChian/index.vue'),
      children: [
          { path: '/edit', name: 'edit',component: () => import('@/views/User/ApprovalProcess/ApprovalChian/edit.vue') }
      ]
  },*/
  {
    path: "/approvalChainEdit",
    name: "Approval Chain Edit",
    component: () =>
      import("@/views/User/ApprovalProcess/ApprovalChain/edit.vue"),
    children: [
      {
        path: "",
        name: "configurationApprovalChainDetail",
        component: () =>
          import(
            "@/views/User/ApprovalProcess/components/approvalChainTable.vue"
          ),
        meta: { keepAlive: true },
      },
      {
        path: "/customApprovalChainFirst",
        name: "customApprovalChainFirst",
        component: () =>
          import(
            "@/views/User/ApprovalProcess/components/customApprovalChainTableFirst.vue"
          ),
        meta: { keepAlive: true },
      },
      {
        path: "/customApprovalChainSecond",
        name: "customApprovalChainSecond",
        component: () =>
          import(
            "@/views/User/ApprovalProcess/components/customApprovalChainTableSecond.vue"
          ),
        meta: { keepAlive: true },
      },
      {
        path: "/customApprovalChainThird",
        name: "customApprovalChainThird",
        component: () =>
          import(
            "@/views/User/ApprovalProcess/components/customApprovalChainTableThird.vue"
          ),
        meta: { keepAlive: true },
      },
    ],
  },
];

// Budget 路由
import { OfferType, BudgetTemplate, AddOffer } from "@/views/Budget/index";
import FleetConfiguration from "@/views/Budget/FleetConfiguration.vue";
const budgetRouters: Array<RouteRecordRaw> = [
  {
    path: "/addoffer/:id/:name/:status",
    name: "Add Offer",
    component: AddOffer,
  },
  {
    path: "/addofferfleet/:id/:name/:status",
    name: "Add Offer Fleet",
    component: FleetConfiguration,
  },
  {
    path: "/budgetinput",
    name: "Budgut Input",
    component: BudgutInput,
  },
  {
    path: "/budgutinputcontrolling",
    name: "Budgut Input Controlling",
    component: BudgutInputControlling,
  },
  {
    path: "/budgetAllocation/:siOfferId",
    name: "budgetAllocation",
    component: BudgetAllocation,
    props: (route) => ({
      pgmId: route.query.pgmId,
      siOfferId: route.params.siOfferId,
    }),
  },
  {
    path: "/budgetReport/:id",
    name: "budgetReport",
    component: BudgetReport,
    props: true,
  },
  {
    path: "/budgutexcel",
    name: "Budgut Excel",
    component: BudgutExcel,
  },
  {
    path: "/offertype",
    name: "Offer Type",
    component: OfferType,
    beforeEnter: (to, from) => {
      //to是当用户点击进入当前页面的时候,我们可以进行一些拦截设置
      //from当来自其他页面进入当前页面的时候，我们也可以进行拦截提示用户
      if (from.name !== "Add Offer") {
        sessionStorage.setItem("status", "active");
      }
    },
  },
  {
    path: "/budgutemplate",
    name: "Budgut Template",
    component: BudgetTemplate,
  },
  {
    path: "/budgutexcel",
    name: "Budgut Excel",
    component: BudgutExcel,
  },
];

// program 路由
const programRouters: Array<RouteRecordRaw> = [
  {
    path: "/programdesign",
    component: ProgramDesign,
    children: [
      { path: "design", name: "Design", component: Design },
      { path: "combinabilitysetting", component: CombinabilitySetting },
    ],
  },
  {
    path: "/wordingedit",
    name: "Wording Edit",
    component: () => import("@/views/Program/Wording/WordingEdit.vue"),
  },
  {
    path: "/wordingcompare",
    name: "Wording Compare",
    component: () => import("@/views/Program/Wording/WordingCompare.vue"),
  },
  {
    path: "/coverpagedit",
    name: "Cover Page Edit",
    component: () => import("@/views/Program/Wording/CoverPageEdit.vue"),
  },
];

// FA 路由
const faRouters: Array<RouteRecordRaw> = [
  {
    path: "/calculatormaintain",
    name: "Calculator Maintain",
    component: CalculatorMaintain,
  },
  {
    path: "/calculator/status",
    name: "Calculator Status",
    component: CalculatorStatus,
  },
  {
    path: "/finance/setting",
    name: "Fa Setting",
    component: FaSetting,
  },
  {
    path: "/estimated/penetration",
    name: "Estimated Penetration",
    component: EstimatedPer,
  },
  {
    path: "/calculator/version",
    name: "Calculator Version",
    component: CalculatorVersion,
  },
  {
    path: "/calculator/reopen",
    name: "Calculator Reopen",
    component: CalcelatorReopen,
  },
];

// 审批流 路由
const approvalRouters: Array<RouteRecordRaw> = [
  // sales
  {
    path: "/approval/sales/programHome",
    name: "programHome",
    component: ProgramHome,
  },
  {
    path: "/approval/sales/createPackage",
    name: "createPackage",
    component: CreatePackage,
    props: (route) => ({ batchId: route.query.batchId }),
  },
  {
    path: "/approval/sales/packageHome",
    name: "packageHome",
    component: PackageHome,
  },
  {
    path: "/approval/sales/releasePackageHome",
    name: "releasePackageHome",
    component: ReleasePackageHome,
  },
  {
    path: "/approval/sales/releaseSetting",
    name: "releaseSetting",
    component: ReleaseSetting,
    props: (route) => ({
      releaseId: route.query.releaseId,
      status: route.query.status,
    }),
  },
  // release
  {
    path: "/approval/sales/releaseSetting/release",
    name: "Release",
    component: () =>
      import(
        "@/views/Approve/SalesRoleApprove/ReleaseSetting/Release/index.vue"
      ),
  },
  //management
  {
    path: "/approval/mg/packageHome",
    name: "mgPackageHome",
    component: MgPackageHome,
  },
  {
    path: "/approval/mg/package/:id",
    name: "mgPackageDetail",
    component: MgPackageDetail,
    props: (route) => ({ id: route.params.id, tag: route.query.tag }),
  },
  {
    path: "/sales/measure",
    name: "salesMeasure",
    component: () => import("@/views/Approve/SalesMeasure/Index.vue"),
  },
  {
    path: "/measure/detail",
    name: "sales Measure Detail",
    component: () => import("@/views/Approve/SalesMeasure/Detail.vue"),
  },
  // mobile
  {
    path: "/mobile/management/pending/approval",
    name: "Mobile Management Pending Approval",
    meta: {
      isMobile: true,
    },
    component: () => import("@/views/Approve/Mobile/Index.vue"),
  },
  {
    path: "/mobile/specific/program/batch",
    name: "Mobile Specific Program Batch",
    meta: {
      isMobile: true,
    },
    component: () => import("@/views/Approve/Mobile/SpecificProgramBatch.vue"),
  },
  //controlling
  {
    path: "/approval/controlling/programHome",
    name: "controllingProgramHome",
    component: ControllingProgramHome,
    children: [
      {
        path: "",
        name: "controllingProgramHome Program Confirm",
        component: () =>
          import(
            "@/views/Approve/ControllingRoleApprove/ProgramHome/components/ProgramApprove.vue"
          ),
        meta: {
          title: "Program Confirm",
          showInTab: true,
        },
      },
      {
        path: "guidelineReport",
        name: "controllingProgramHome Guideline Report",
        component: () => import("@/views/Budget/BudgetReport/Content.vue"),
        meta: {
          title: "Guideline Report",
          showInTab: true,
        },
      },
      {
        path: "setGuideline",
        name: "controllingProgramHome Set Guideline",
        component: () =>
          import(
            "@/views/Approve/ControllingRoleApprove/ProgramHome/components/SetGuideline.vue"
          ),
        meta: {
          title: "Set Guideline",
          showInTab: true,
        },
      },
    ],
  },
  {
    path: "/wordingbudget",
    name: "wordingBudget",
    component: WordingBudget,
  },
  // F&A
  {
    path: "/approval/fa",
    name: "ApproveFA",
    component: ApproveFA,
  },
  // 工作流配置
  {
    path: "/approvalChain",
    name: "ApprovalChain",
    component: () =>
      import("@/views/User/ApprovalProcess/ApprovalChain/index.vue"),
    children: [
      {
        path: "",
        name: "ProgramChain",
        meta: {
          showInTab: true,
          title: "Program Approval Chain",
          permission: { path: "/approvalChain", privcd: "AC-01" },
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/ApprovalChain/ProgramChain/index.vue"
          ),
      },
      {
        path: "payoutChain",
        name: "PayoutChain",
        meta: {
          showInTab: true,
          title: "Payout Approval Chain",
          permission: { path: "/approvalChain", privcd: "AC-02" },
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/ApprovalChain/PayoutChian/index.vue"
          ),
      },
      {
        path: "roleSetting",
        name: "roleSetting",
        meta: {
          showInTab: true,
          title: "Approval Role Setting",
          permission: { path: "/approvalChain", privcd: "AC-03" },
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/ApprovalChain/RoleSetting/index.vue"
          ),
      },
    ],
  },
  // 审批代理信息配置
  {
    path: "/approval/delegationSetting",
    name: "ApprovalDelegationSetting",
    component: () =>
      import("@/views/User/ApprovalProcess/ApprovalDelegation/index.vue"),
  },
  {
    path: "/spotCheckingConfiguration",
    name: "spotCheckingConfiguration",
    component: () =>
      import(
        "@/views/User/ApprovalProcess/SpotCheckingConfiguration/index.vue"
      ),
    children: [
      {
        path: "",
        name: "sampleCheckRule",
        meta: {
          showInTab: true,
          title: "Sample Check Rule",
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/SpotCheckingConfiguration/SampleCheckRule/index.vue"
          ),
      },
      {
        path: "billRate",
        name: "billRate",
        meta: {
          showInTab: true,
          title: "Bill Rate",
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/SpotCheckingConfiguration/BillRate/index.vue"
          ),
      },
      {
        path: "serviceVat",
        name: "serviceVat",
        meta: {
          showInTab: true,
          title: "Service VAT",
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/SpotCheckingConfiguration/ServiceVat/index.vue"
          ),
      },
      {
        path: "contractActivationDays",
        name: "contractActivationDays",
        meta: {
          showInTab: true,
          title: "Contract Activation Days",
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/SpotCheckingConfiguration/ContractActivationDays/index.vue"
          ),
      },
      {
        path: "floorPlanDays",
        name: "floorPlanDays",
        meta: {
          showInTab: true,
          title: "Floor Plan Days",
        },
        component: () =>
          import(
            "@/views/User/ApprovalProcess/SpotCheckingConfiguration/FloorPlanDays/index.vue"
          ),
      },
    ],
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/index.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    // redirect: ''
  },
  ...uamRouters,
  ...budgetRouters,
  ...programRouters,
  // SSSC
  {
    path: "/ssscindex",
    name: "SSSC Index",
    component: SSSCIndex,
  },
  // FA路由
  ...faRouters,
  //审批流路由
  ...approvalRouters,
  // RetailValidation
  ...rvRouters,
  //claim
  ...claimRouters,
  //checking
  ...checkingRouters,
  //payment
  ...pmtRouters,
  //vehicle
  ...systemVehicleRouters,
  //closing
  ...closingRouters,
  //forecast
  ...forecastRouters,
  //planning
  ...planningRouters,
  //vehiclelicense
  ...vlRouters,
  //dealerMaster
  ...dealerMasterDataRouters,
  // setting
  ...settingRouters,
  //taskcenter
  ...taskCenterRouters,
  //claimDelay
  ...delayRouters,
  //communication
  ...communicationRouters,
  //delayCallback
  ...delayCallbackRouters,
  //salesRepotring
  ...salesRepotringRouters,
  //guiDang
  ...guiDangRouters,
  ...reportingRouters,
  //localRegistration
  ...localRegistrationRouters,
  //faq
  ...faqRouters,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

import store from "@/store";

/* TODO 登录拦截-相关 勿删 */
const routerNext = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (isMobile()) {
    if (!to.meta?.isMobile) {
      // next({ name: 'Mobile Pending Approval' });
      next({ path: "/" + localStorage.getItem("userUrl") });
      return;
    } else {
      next();
    }
  } else {
    next();
    return;
  }
};

router.beforeEach((to, from, next) => {
  const Authorization = store.state.Authorization;
  if (to.name === "Login") {
    routerNext(to, from, next);
  } else if (Authorization) {
    const data = {
      action: "VIEW",
      button: "NA",
      menu: to.name,
    };
    PageView(data);
    routerNext(to, from, next);
  }
});
/* TODO 登录拦截-相关 end */

export default router;
