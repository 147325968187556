
import { defineComponent, ref, reactive, toRefs, onMounted, h } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { PageOptionProps, LegalEntity, Entity } from "./types";
import { Modal } from "ant-design-vue";
import store from "@/store";
import { addDataKey } from "@/utils";
import {
  usersFindall,
  userChangestatus,
  getLegalEntityOptions,
  getEntityOptions,
} from "@/API/user";
import useUserAddEdit from "@/hooks/useUserAddEdit";
import useSearch from "@/hooks/useSearch";
import blobDownload from "@/utils/blobDownload";
import useTableHeight from "@/hooks/useTableHeight";
// 表格的列
const columns = [
  {
    title: "No.",
    dataIndex: "key",
    key: "key",
    width: "6%",
  },
  {
    // title: 'Name',
    dataIndex: "usrnm",
    key: "usrnm",
    slots: { title: "customName" },
    width: "12%",
  },
  {
    // title: 'Type',
    dataIndex: "usrtpnm",
    key: "usrtpnm",
    slots: { title: "customType" },
    width: "8%",
  },
  {
    // title: 'Full Name',
    dataIndex: "fulnm",
    key: "fulnm",
    slots: { title: "customFulnm" },
    width: "10%",
  },
  {
    // title: 'Org',
    dataIndex: "orgnm",
    key: "orgnm",
    slots: { title: "customOrg", customRender: "orgnm" },
    width: "25%",
  },
  {
    // title: 'Email',
    dataIndex: "email",
    key: "email",
    slots: { title: "customEmail" },
  },
  {
    // title: 'Operation',
    key: "operation",
    slots: { title: "customOperation", customRender: "operation" },
    width: "27%",
  },
];

export default defineComponent({
  components: {},
  setup() {
    const stateData = useStore();
    const innerHeight = ref(window.screen.height);
    // 分页配置
    const pageOptions = reactive({
      pageSize: 0,
      current: 0,
      total: 1,
      pageSizeOptions: ["10", "20", "30", "40"],
      showSizeChanger: true,
      showQuickJumper: true,
    });
    const { tableHeight } = useTableHeight(true, ".ant-table-pagination");
    const router = useRouter();
    // 表单的数据
    const ruleForm = ref<null | HTMLElement>(null);

    // Legal Entity
    const legalEntityOptions = ref<LegalEntity[]>();

    // Entity
    const entityOptions = ref<Entity[]>();

    // 表格
    const {
      enName,
      cnName,
      userType,
      searchSubmit,
      privilegeGroup,
      searchChange,
      boolSearch,
    } = useSearch(pageOptions, usersFindall, "type");

    // 获取Legal Entity
    const getLegalEntity = () => {
      getLegalEntityOptions().then((res) => {
        legalEntityOptions.value = res;
      });
    };

    // 获取Entity下拉数据
    const getEntity = () => {
      getEntityOptions().then((res) => {
        entityOptions.value = res;
      });
    };

    // 表格请求数据, 初始表格数据
    const initTableData = (pageIndex = 0, pageSize = 10) => {
      const params = {
        pageIndex: pageIndex,
        pageSize,
      };
      usersFindall({ params: params }).then((res) => {
        privilegeGroup.value = addDataKey(res.content);
        pageOptions.pageSize = res.size;
        pageOptions.total = res.totalElements;
        pageOptions.current = pageIndex + 1;
      });
    };
    // 表格选择页码页面
    const changePage = (pagination: PageOptionProps) => {
      if (!userType.value && !enName.value && !cnName.value) {
        initTableData(pagination.current - 1, pagination.pageSize);
      } else {
        const params = {
          type: userType.value,
          name:
            store.state.lang === "en"
              ? enName.value.trim()
              : cnName.value.trim(),
          pageIndex: pagination.current - 1,
          pageSize: pagination.pageSize,
        };
        usersFindall({ params: params }).then((res) => {
          privilegeGroup.value = addDataKey(res.content);
          pageOptions.pageSize = res.size;
          pageOptions.total = res.totalElements;
          pageOptions.current = pagination.current;
        });
      }
    };

    const {
      dealerItAdmin,
      handleAddPG,
      handleEdit,
      handleCancel,
      submitAddPG,
      ruleFormRef,
      grptpChange,
      grpChange,
      entityChange,
      isAdd,
      modalTitle,
      visible,
      formData,
      orgFul,
      isShowEntity,
      handleChangeNameToUpperCase,
    } = useUserAddEdit();
    // 删除
    const delVisible = ref(false);
    const handleDisable = (id: number) => {
      Modal.confirm({
        class: "my-confirm",
        content: h("div", {}, [
          h("i", { class: "font_family icon-shezhi confirm-h" }, ""),
          h(
            "h1",
            { style: "font-size: 18px;" },
            "Are you sure to change the status of user?"
          ),
        ]),
        onOk() {
          return new Promise((resolve, reject) => {
            const params = { usrid: id };
            //接口
            userChangestatus(params).then((res) => {
              let nowState = "";
              if (res.state) {
                nowState = "true";
              } else {
                nowState = "false";
              }
              sessionStorage.setItem("state", nowState);
              window.location.reload();
            });
            // console.log(resolve, reject)
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 更新数据
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {
          console.log("cancel");
        },
      });
    };
    // 导出excel
    const expotToExcel = () => {
      const params = {
        url: "/usapi/users/exportuserexcel",
      };
      blobDownload(params);
    };
    onMounted(() => {
      getLegalEntity();
      getEntity();
      initTableData();
    });
    return {
      dealerItAdmin,
      stateData,
      userType,
      ruleFormRef,
      isAdd,
      modalTitle,
      innerHeight,
      columns,
      // 表格数据
      privilegeGroup,
      pageOptions,
      changePage,
      // 添加
      handleAddPG,
      submitAddPG,
      handleCancel,
      grptpChange,
      // 删除
      handleDisable,
      delVisible,
      enName,
      cnName,
      visible,
      legalEntityOptions,
      entityOptions,
      ...toRefs(formData),
      router,
      ruleForm,
      searchSubmit,
      searchChange,
      boolSearch,
      handleEdit,
      grpChange,
      entityChange,
      orgFul,
      expotToExcel,
      tableHeight,
      isShowEntity,
      handleChangeNameToUpperCase,
    };
  },
});
