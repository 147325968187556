
import { defineComponent, ref, onMounted, toRaw, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { RoleTableProps, TreeProps, ColumnsProps, SelectMenu, APIMenuProps, RoleMPProps, APIMenu, TableCheckedDataProps, ActionPropsRole } from './types'
import { ArrowRightOutlined } from '@ant-design/icons-vue';
import { roleFindbyprivgrpid, roleFindassignmen, roleSaveassignment } from '@/API/user'
import { getPermissions } from "@/utils"

// 表格的列
const columns: ColumnsProps[] = [
  {
    // title: 'Action Name',
    dataIndex: 'privnmcn',
    slots: { title: 'customTitle', customRender: 'privnmcn' }
  },
  {
    // title: 'Action Code',
    dataIndex: 'privcd',
    slots: { title: 'customCode' }
  },
  {
    // title: 'Action Type',
    dataIndex: 'privtpnm',
    slots: { title: 'customType' }
  },
]



const columnsAPI: ColumnsProps[] = [
  {
    // title: 'API Name',
    dataIndex: 'apinm',
    slots: { title: 'customApinm'}
  },
  {
    title: 'Http Method',
    dataIndex: 'apimhd',
  },
  {
    // title: 'Description',
    dataIndex: 'apidsc',
    slots: { title: 'customApidsc'}
  },
]


export default defineComponent({
    components: {
        ArrowRightOutlined
    },
    name: 'RoleSetting',
    setup () {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        // （受控）展开指定的树节点
        const expandedKeys = ref()
        // 当前点中的节点
        const clickKeys = ref()
        // 当前勾选的节点
        const checkedKeys = ref()
        const treeData = ref<TreeProps[]>([])
        let selectTable = {}
        const tableCheckedData = ref<TableCheckedDataProps>({})
        const replaceFields = ref()
    
        // API
        const apiMenu = ref<APIMenuProps[]>([])
        // 表格数据
        const tableDataAPI = ref()
        // 表格
        const selectedRowKeysAPI = ref()


        
        // 替换项
        replaceFields.value = {
            title: store.state.lang === 'en' ? 'mnunmen' : 'mnunmcn',
        }
        watch(() => store.state.lang, () => {
            replaceFields.value = {
                title: store.state.lang === 'en' ? 'mnunmen' : 'mnunmcn',
            }
        })

        // 表格数据
        const tableData = ref<RoleTableProps[]>()
        // 点中当前的树节点
        let clickTreeNode: string

        const findMenuItem = (arr: TreeProps[], id: string): TreeProps | null => {
            if (arr === null) {
                return null
            }
            for (const obj of arr) {
                if (obj.mnuid === id) {
                    return obj
                }
                const menuItem = findMenuItem(obj.children, id)
                if (menuItem) {
                    return menuItem
                }
            }
            return null
        }
        // 表格
        const selectedRowKeys = ref()

        // 初始化，三个接口合一apilist: [],mnulist: [],privlist: []
        // mnulist
        const formatMenuSelect = (data: SelectMenu[], tree: TreeProps[]) => {
            const arr: string[]  = []
            for (const item of data) {
                const menuItem = findMenuItem(tree, item.mnuid)
                if (!menuItem?.children.length) {
                    arr.push(item.mnuid)
                }
            }
            return arr
        }
        // privlist
        const formatActionSelect = (data: SelectMenu[]) => {
            const obj: TableCheckedDataProps = {}
            data.forEach(item => {
                if (item.privids && item.mnuid) {
                    const arr: string[] = []
                    item.privids.forEach(val => {
                        if (val.privid) {
                            arr.push(val.privid)
                        }
                    })
                    obj[item.mnuid] = arr
                }
            })
            return obj
        }
        // privlist
        const formatActionSelectObj = (data: SelectMenu[]) => {
            data.forEach(item => {
                if (item.privids && item.mnuid) {
                    selectTable[item.mnuid] = item.privids
                }
            })
            return selectTable
        }
        // 三个接口合一apilist
        const formatAPISelect = (data: APIMenu[]) => {
            const arr: string[]  = []
            data.forEach(item => {
                if (item.apiid) {
                    arr.push(item.apiid)
                }
            })
            return arr
        }
        // 根据用户权限，给树和表格添加是否可选的
        const formatTree = (data: TreeProps[]): TreeProps[] => {
            data.forEach(item => {
                item.disableCheckbox = true
                if (item.children.length > 0) {
                    item.children.forEach(val => {
                        val.disableCheckbox = true
                    })
                }
            })
            return data
        }
        // checkedKeys.value这个打印出来是Proxy{}格式的，[...checkedKeys.value]   -> [2, 5]
        const init = () => {
            const params = {
                privgrpid: route.params.id
            }
            const params1 = {
                rolid: route.params.rolid
            }
            
            Promise.all([roleFindbyprivgrpid({ params: params }), roleFindassignmen({ params: params1 })]).then(res => {
                treeData.value = getPermissions('/role').filter(item => item.privcd === 'SETTINGEDIT').length > 0 ? res[0].mnulist : formatTree(res[0].mnulist)
                // treeData.value = res[0].mnulist
                apiMenu.value = res[0].apilist
                checkedKeys.value = formatMenuSelect(res[1].mnuList, res[0].mnulist)
                tableCheckedData.value = formatActionSelect(res[1].privList)
                selectedRowKeysAPI.value = formatAPISelect(res[1].apiList)
                selectTable = formatActionSelectObj(res[1].privList)
            })
        }
        // 树的点击选择时，出现对应表格数据,给表格赋值，还有选中的值
        // selectedKeys选择的节点， info选择节点的数据, 是id
        const onSelect = (treeSelectedKeys: string[], info: any) => {
            for (const item of info.node.dataRef.privlist) {
                item.key = item.privid
            }
            // 树点击时，表格赋值
            tableData.value = info.node.dataRef.privlist
            // clickKeys.value = info.node.dataRef.privlist
            // 当前树节点改变
            clickTreeNode = treeSelectedKeys[0]
            // 表格已勾选，根据接口的数据来回显
            selectedRowKeys.value = tableCheckedData.value[clickTreeNode]
        }
        // 过滤当前表格对应的树的节点
        const checkedIndex = () => {
            return checkedKeys.value.filter((item: string) => item !== clickTreeNode)
        }
        const hasChecked = () => {
            return checkedKeys.value.findIndex((item: string) => item === clickTreeNode)
        }
        // 表格勾选的回调函数
        const onSelectChange = (selected: string[], selectedRows: string[]) => {
            selectedRowKeys.value = selected
            if (selectedRows.length > 0) {
                // 表格的勾选导致树的勾选
                if (hasChecked() === -1) {
                    checkedKeys.value.push(clickTreeNode)
                }
                tableCheckedData.value[clickTreeNode] = selected
                selectTable[clickTreeNode] = toRaw(selectedRows)
            } else {
                // 表格的取消勾选,树取消勾选
                checkedKeys.value = checkedIndex()
                delete tableCheckedData.value[clickTreeNode]
                delete selectTable[clickTreeNode]
            }
        }
// API--------------------------------------------------------------------------------------API
        
        
        // 点击菜单过滤对应的API
        const clickApiMenu = (item: APIMenuProps) => {
            item.apilist.forEach(val => {
                val.key = val.apiid
            })
            tableDataAPI.value = item.apilist
        }
        
        // 表格勾选的回调函数
        const onSelectChangeAPI = (selected: string[]) => {
            // 表格的勾选
            selectedRowKeysAPI.value = selected
            // selectTableAPI.value = selectedRows
        }

// submit-----------------------------------------------------------------------------------
        // API提交的params
        const pgAPISubmit = () => {
            let objP: RoleMPProps;
            const arr: RoleMPProps[] = []
            selectedRowKeysAPI.value.forEach((item: string) => {
                objP = {
                    "apiid": item,
                    "rolid": route.params.rolid,
                    "state": true
                }
                arr.push(objP)
            })
            return arr
        }
        // 得到
        const findParent = (arr: TreeProps[], id: string): string => {
            if (arr === null) return ''
            for (const obj of arr) {
                if (obj.mnuid === id) {
                    return obj.parentid ? obj.parentid : ''
                }
                const ret = findParent(obj.children, id)
                if (ret) return ret
            }
            return '';
        }
        const findParentIds = (arr: TreeProps[], id: string): string[] => {
            if (arr === null) return []
            for (const obj of arr) {
                if (obj.mnuid === id) {
                    return [obj.parentid ? obj.parentid : '']
                }
                const parentIds = findParentIds(obj.children, id)
                if (parentIds.length) {
                  return [obj.parentid ? obj.parentid : '', ...parentIds]
                }
            }
            return [];
        }
        const getParentId = () => {
            const arr: string[] = []
            if (checkedKeys.value.length > 0 && treeData.value.length > 0) {
                for (const key of checkedKeys.value) {
                    arr.push(...findParentIds(treeData.value, key))
                }
            }
            return arr
        }
        // Menu的params
        const pgMenuSubmitParams = () => {
            if (checkedKeys.value.length < 1) return []
            let objP: RoleMPProps;
            const arr: RoleMPProps[] = []
            const arrConcat = Array.from(new Set(getParentId().concat(checkedKeys.value))).filter(val => val !== '0')
            for (const item of arrConcat) {
                objP = {
                    "mnuid": item,
                    "rolid": route.params.rolid,
                    "state": true,
                    rolmnuid: ''
                }
                arr.push(objP)
            }
            return arr
        }
        // action的parmam
        const pgActionSubmitParams = () => {
            let objP: ActionPropsRole;
            const arr = []
            for (const key in selectTable) {
                const arr1: ActionPropsRole[] = []
                    for (const item of selectTable[key]) {
                        objP = {
                            "rolid": route.params.rolid,
                            "privid": item.privid,
                            "mnuid": key,
                            "state": true
                        }
                        arr1.push(objP)
                    }
                    arr.push(arr1)
            }
            return arr.flat(2)
        }
        
        // 提交按钮
        const submitPGSetting = () => {
            const params = {
                apiList: pgAPISubmit(),
                mnuList: pgMenuSubmitParams(),
                privList: pgActionSubmitParams(),
                rolid: route.params.rolid
            }
            roleSaveassignment(params).then(() => {
                message.success('Save successed!')
            })
        }
        const getCheckboxProps = () => ({
          disabled: true
        })
        onMounted(() => {
            init()
        })
        return {
            store,
            // template路由跳转
            router,
            // 树的替换字段cn, en
            replaceFields,
            clickKeys,
            checkedKeys,
            selectedRowKeys,
            expandedKeys,
            treeData,
            columns,
            tableData,
            tableCheckedData,
            onSelect,
            onSelectChange,

            // api
            apiMenu,
            selectedRowKeysAPI,
            onSelectChangeAPI,
            columnsAPI,
            tableDataAPI,
            clickApiMenu,
            // 提交
            submitPGSetting,
            getPermissions,
            getCheckboxProps
        }
    }
})
