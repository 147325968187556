
import { defineComponent, computed, ref } from "vue";
import store from "@/store";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { findCurrentUser, outLogin } from "@/API/user";
import { message } from "ant-design-vue";
interface EventMenu {
  key: number;
  keyPath: string[];
  item: string[];
  domEvent: MouseEvent;
}
const users = [
  {
    id: "1-u-1",
    usrnm: "DealerUser",
    fulnm: "Dealer User",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.OEMITADMIN",
  },
  {
    id: "2-u-2",
    usrnm: "OEMITADMIN",
    fulnm: "OEM IT Admin",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.OEMITADMIN",
  },
  {
    id: "3-u-3",
    usrnm: "ERIN00",
    fulnm: "经销商管理员",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.ERIN00",
  },
  {
    id: "4-u-3",
    usrnm: "CONTRO",
    fulnm: "OEM controlling",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.CONTRO",
  },
  {
    id: "5-u-5",
    usrnm: "FINANCE",
    fulnm: "Financial User",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.FINANCE",
  },
  {
    id: "6-u-6",
    usrnm: "SALES",
    fulnm: "Sales User",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.SALES",
  },
  {
    id: "7-u-7",
    usrnm: "MBLC",
    fulnm: "MBLC",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.MBLC",
  },
  {
    id: "8-u-8",
    usrnm: "MBAFC",
    fulnm: "MBAFC",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.MBAFC",
  },
  {
    id: "9-u-9",
    usrnm: "APU1",
    fulnm: "ApprovalUser1",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.APU1",
  },
  {
    id: "10-u-10",
    usrnm: "APU2",
    fulnm: "ApprovalUser2",
    token: "OU1v6w9v6zbxzG0T4ntkVy5ZY.APU2",
  },
];
export default defineComponent({
  methods: {
    // 切换语言
    handleChange() {
      if (this.$i18n.locale === "zh") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "zh";
      }
      store.commit("updateLang", this.$i18n.locale);
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    /* TODO 登录拦截-相关 勿删 */
    const hanlderLogout = () => {
      if (process.env.VUE_APP_SSO === "yes") {
        const Authorization = store.state.Authorization;
        const params = {
          token: Authorization,
        };
        outLogin(params)
          .then((res) => {
            store.state.user.usrnm = "";
            store.commit("updateAuthorization", "");
            window.location.href = res;
            localStorage.removeItem("userUrl");
          })
          .catch((err) => {
            message.error(err);
          });
      } else {
        store.commit("updateAuthorization", "");
        router.push("/login");
      }
    };
    /* TODO 登录拦截-相关 end */

    let helpCenterUrl = "";
    const helpCenterStr = ref("");
    const hasHelpCenter = computed(() => {
      const helpCenterMenuList = store.state.user.menulist.filter(
        (menu: any) => {
          return [
            "Help Center Mangement",
            "Help Center Enquiry",
            "帮助中心查询",
          ].includes(menu.mnunmen);
        }
      );
      if (helpCenterMenuList.length > 0) {
        helpCenterUrl = helpCenterMenuList[0].mnuuri;
        helpCenterStr.value = helpCenterMenuList[0].mnunmen;
        return true;
      }
      return false;
    });

    const gotoFaq = () => {
      router.push(helpCenterUrl);
    };

    return {
      hanlderLogout,
      users,
      gotoFaq,
      hasHelpCenter,
      helpCenterStr,
    };
  },
});
