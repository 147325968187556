import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { message } from "ant-design-vue";
import store from "@/store";
import router from "@/router";
// 错误状态
// import { errStatus } from '@/utils/api/errStatus'
const notLoadUrl = [
  "/bgapi/allocation/budget/export/view",
  "/bgapi/budget/findbudget_data",
  "/bgapi/budget/sum_kufri_budget",
  "/rvapi/basic/certificate/batchInvoice/upload",
  "/rvapi/basic/certificate/batchLicense/upload",
  "/rvapi/basic/apply/progress",
  "/pcapi/closing/dataPreparation/importDealerStock",
  "/pcapi/closing/dataPreparation/importRT",
  "/pcapi/closing/dataPreparation/importWSBI",
  "/pcapi/closing/dataPreparation/upload",
  "/pcapi/closing/dataPreparation/collectInterfaceData/result",
  "/rvapi/vl/handlefile/license/upload",
  "/rvapi/vl/singleSubmitResult",
  "/rvapi/vl/handlefile/progress",
  "/rvapi/vl/handlefile/batchLicense/upload",
  "/rvapi/delay/batchUpload/license",
  "/rvapi/delay/batchUpload/invoice",
  "/claimapi/ocr/claim/idCard",
  "/claimapi/ocr/claim/batchRegistration",
  "/claimapi/ocr/claim/batchUCInvoice",
  "/claimapi//ocr/claim/idCard",
  "/claimapi/ocr/claim/registration",
  "/claimapi/ocr/claim/ucInvoice",
];
export const baseURL = "/oasis";
axios.defaults.baseURL = baseURL;
axios.defaults.paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: "repeat" });

let requestNumber = 0;
const isNoLoading = (url: string) => {
  return notLoadUrl.includes(url);
};

axios.interceptors.request.use((config) => {
  if (store.state.isPolling) {
    if (!isNoLoading(config.url as string)) {
      requestNumber = requestNumber + 1;
    }
    store.commit(
      "updateSpinning",
      !notLoadUrl.filter((url) => {
        return String(config.url).includes(url);
      }).length
    );
  }

  // 本地调试使用
  // store.commit(
  //   "updateAuthorization",
  //   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJDWkhJSEFOIiwiaXNzIjoiT0FTSVMiLCJleHAiOjE2NjM0ODczNDIzODgsImlhdCI6MTY2MDg5NTM0Mn0.KdEJ0QDUzJ7hDts9v87vAU8yMkZvTsF5SHo2O9X7Bs2VMCjHf63rAvHxSKcbHfVIQnjz1LI05jB0IwbXheWHow"
  // );
  // uat 环境使用
  // store.commit('updateAuthorization', 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJPQUFETUlOIiwiaXNzIjoiT0FTSVMiLCJleHAiOjE2NDE5NTcwMDEwNDQsImlhdCI6MTYzOTM2NTAwMX0.xBH2SDvUCtslxemw4pPSPxr1h4n8v-I2hAOoJUmx5pGkWFHr6686dlKM4R2Xppta0MbY_MjeaegRP0Ywqvch4g')
  // tranining 环境使用
  // store.commit('updateAuthorization', 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJPQUFETUlOIiwiaXNzIjoiT0FTSVMiLCJleHAiOjE2NDM4NzQ4ODYxNjIsImlhdCI6MTY0MTI4Mjg4Nn0.w2CUnmWgT6Sm9sWKesz7Fu8AkaHHVL73-8COSWt_i_owSWkyoGj3sZgSusctxQ3bp57XWpvRwG3yInclSeRtjw')
  // NST Group MSRP Override 的权限账号
  // store.commit('updateAuthorization', 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJMWUlOR05BIiwiaXNzIjoiT0FTSVMiLCJleHAiOjE2Mzg0MzM5NDk1OTEsImlhdCI6MTYzNTg0MTk0OX0.7EeVONG6i1c8bj2j9bAWglbpYuY1quqRDDhEdal9pGJCfNpLzPMB23tSBYv4dhhcXkvoUrZp9BduDKkQp4EAEA')
  const Authorization = store.state.Authorization;
  Authorization && (config.headers["Authorization"] = Authorization);

  // config.headers['Authorization'] = store.state.Authorization;
  // (config.headers['Authorization'] = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJPQUFETUlOIiwiaXNzIjoiT0FTSVMiLCJleHAiOjE2Mzc5NzIxMTcxNzAsImlhdCI6MTYzNTM4MDExN30.sFkZvZ42xtD7RuGa8_DP_j50ZVutl2H6ggL_jIQmaZht1BUjuj9l1MDgYS1y5I098E2kHxpGQm4_Li2jdy0QlA")

  config.headers["Authorization"] = store.state.Authorization;
  config.headers["accept"] = "*/*";
  // config.headers['X-USER-INFO'] = 'eyJ1c3JpZCI6ImZmODA4MDgxNzYxOGFmZjgwMTc2MThiMGJmZGQwMDA4IiwidXNybm0iOiJPRU1JVEFETUlOIiwiZnVsbm0iOiJPRU0gSVQgQWRtaW4iLCJlbWFpbCI6Ik9BU0lTMDlAT0FTSVMuY29tIiwidXNydHAiOjEwMDAxLCJ1c3J0cG5tIjoiT0VNIiwib3JnaWQiOiIyZGU5ZjQ3NGE4N2E0NGEwYTE3OTM4YjIxYmRjODdlOSIsIm9yZ25tY24iOm51bGwsIm9yZ25tZW4iOm51bGwsInN0YXRlIjp0cnVlLCJjcnRlZCI6MTYwNjgxNzU5NzY0NywiY3J0YnkiOiJPQVNJU0lUQURNSU4wOSIsImNydGJ5bm0iOiJPQVNJU0lUQURNSU4wOSJ9';

  return config;
});

/* TODO 注释代码是和登录拦截相关的，勿删 */
axios.interceptors.response.use(
  (config) => {
    // /oauth/token/exchange接口验证通过在headers中返回authorization
    if (!isNoLoading(config.config.url as string)) {
      requestNumber = requestNumber - 1;
    }
    if (requestNumber === 0) {
      store.commit("updateSpinning", false);
    }
    const authorization = config.headers["authorization"];
    authorization && store.commit("updateAuthorization", authorization);
    if (config.config.headers["code-handler"] !== "ignore") {
      if (config.data.code && config.data.code !== "0") {
        message.error(config.data.message);
      }
    }
    return config;
  },
  (error) => {
    const url =
      error.response && error.response.config && error.response.config.url;

    if (!isNoLoading(url as string)) {
      requestNumber = requestNumber - 1;
    }
    if (requestNumber === 0) {
      store.commit("updateSpinning", false);
    }
    // 401 token失效或code无效
    if (
      error.response.status === 401 &&
      (url === "/oauth/token/exchange" || url === "/usapi/users/currentuser")
    ) {
      if (process.env.VUE_APP_SSO === "yes") {
        // 获取重定向地址
        const data = atob(error.response.data.data);
        // token失效清除Authorization
        store.commit("updateAuthorization", "");
        data && (window.location.href = data);
      } else {
        router.push("/login");
      }
    } else {
      if (error.response) {
        if (error.config.headers["code-handler"] !== "ignore") {
          if (
            error.response.data instanceof Blob &&
            error.response.data.type === "application/json"
          ) {
            error.response.data.text().then((res: any) => {
              message.error(JSON.parse(res).message);
            });
          } else if ("message" in error.response.data) {
            message.error(error.response.data.message);
          } else {
            message.error(error.response.data.msg);
          }
        }
      }
    }

    return Promise.reject(error);
  }
);
/* TODO 注释代码是和登录拦截相关的，勿删 end */

export const fetchGet = <T>(url: string, params = {}): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchPost = <T>(
  url: string,
  data = {},
  params = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, params)
      .then((res) => {
        if (res.data.code !== "0") {
          reject(res.data.data);
        }
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchGetMess = <T>(url: string, params = {}): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchPostPic = <T>(
  url: string,
  data = {},
  params = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchPut = <T>(
  url: string,
  data = {},
  params = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, params)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchPutPic = <T>(
  url: string,
  data = {},
  params = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchDelete = <T>(url: string, params = {}): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchDeletePic = <T>(url: string, params = {}): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetch = <T>(config: AxiosRequestConfig): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
