import { ref, reactive, nextTick, watch, onMounted } from 'vue'
import { RoleProps, PrgProps, SelectGlobalProps } from '@/views/User/types'
import { rolesSaverole, rolesUpdaterole, PGfindgroupbytype, rolesFindrolebyid } from '@/API/user'
import { message } from "ant-design-vue"
import { useStore } from 'vuex'
import htmlentities from '@/utils/htmlentities'

const useAddEdit = () => {
    const store = useStore()
    // 增加组的弹窗
    const visible = ref(false);
    // 定义是否添加过
    const isAdd = ref(1)
    const prgOptions = ref<PrgProps[]>([])
    const formData = reactive({
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
        other: '',
        form: {
            grpnm: visible.value ? '': [],
            "privgrpid": "",
            "privgrpnmcn": "",
            "privgrpnmen": "",
            "roldsc": "",
            "rolid": '',
            "rolnmcn": "",
            "rolnmen": "",
            "roltp": store.state.user.usrtp === 10001 ? 0 : 10002,
            "roltpnm": store.state.user.usrtp === 10001 ? []: 'Dealer',
            state: true,
            crtby: '',
            crtbynm: '',
            crted: 0,
        },
        rules: {
            rolnmcn: [
                { required: true, message: 'Please input Chinese name', trigger: 'blur' },
                { max: 100, message: 'Length should be 1 to 100', trigger: 'blur' },
                { pattern: /^([a-z]|[A-Z]|[\u4e00-\u9fa5]|[0-9]|\s){0,}$/g, message: "Please enter Chinese，English Letters and  number", trigger: 'blur'}
            ],
            rolnmen:  [
                { required: true, message: 'Please input English name', trigger: 'blur' },
                { max: 100, message: 'Length should be 1 to 100', trigger: 'blur' },
                { pattern: /^([a-z]|[A-Z]|[0-9]|\s){0,}$/g, message: "Please enter English or number", trigger: 'blur' }
            ],
            roltpnm: [
                {
                    required: true, message: 'Please select a role type', trigger: 'change' 
                }
            ],
            grpnm: [
                {
                    required: true, message: 'Please select a user group type', trigger: 'change' 
                }
            ],
            roldsc: [{ max: 200, message: "Length should be 0 to 200", trigger: "change" }]
        },
    })
    // 显示增加权限组弹窗
    const handleAddPG = () => {
        visible.value = true;
        isAdd.value = 1;
    }
    // 根据type得到pg
    const getGPbyType = (typeid = 10002) => {
        const params = {
            type: typeid
        }
        PGfindgroupbytype({ params: params }).then((res) => {
            prgOptions.value = res
        })
    }
    // 因为下拉不能绑定对象，所以要用change得到label
    const grptpChange = (value: string, option: SelectGlobalProps) => {
        formData.form.roltpnm = value
        formData.form.roltp = +option.key
        getGPbyType(+option.key)
        // type选择之后，把pg清空
        formData.form.grpnm = []
    }
    const findNmById = (id: string) => {
        return prgOptions.value.filter(item => item.privgrpid === id)
    }
      // 因为下拉不能绑定对象，所以要用change得到label
      const grpChange = (value: string, option: SelectGlobalProps) => {
        formData.form.privgrpid = option.key
        const groupOption = findNmById(option.key)
        formData.form.privgrpnmcn = groupOption[0].grpnmcn
        formData.form.privgrpnmen = groupOption[0].grpnmen
    }
    // 获取from DOM元素
    const myFromRef = ref()
    const ruleFormRef = (el: HTMLElement | null)=>{
      nextTick(()=>{
        myFromRef.value = el
      })
    }
    // 提交增加组
    const submitAddPG = () => {
      // seft.$refs[formName]
        myFromRef.value
        .validate()
        .then(() => {
            visible.value = false
            const params = {
                "privgrpid": formData.form.privgrpid,
                "privgrpnmcn": formData.form.privgrpnmcn,
                "privgrpnmen": formData.form.privgrpnmen,
                "roldsc": htmlentities(formData.form.roldsc),
                "rolid": formData.form.rolid,
                "rolnmcn": htmlentities(formData.form.rolnmcn),
                "rolnmen": htmlentities(formData.form.rolnmen),
                "roltp": formData.form.roltp,
                "roltpnm": formData.form.roltpnm,
                state: formData.form.state,
                crtby: formData.form.crtby,
                crtbynm: formData.form.crtbynm,
                crted: formData.form.crted,
            }
            if ( isAdd.value ) {
              rolesSaverole(params).then(() => {
                    myFromRef.value.resetFields();
                    message.success('Save successfully!')
                    window.location.reload()
                })
            } else {
              rolesUpdaterole(params).then(() => {
                    myFromRef.value.resetFields();
                    message.success('Save successfully!')
                    window.location.reload()
                })
            }
        })
        .catch((error: string) => {
            console.log('error', error);
        })
    }
    // 取消增加权限组弹窗
    const handleCancel = () => {
      visible.value = false;
      myFromRef.value.resetFields();
    };
    // 编辑时，把编辑的内容拿回来之后，还需要根据拿回来的type去查pg
    const handleEdit = (row: RoleProps, addNumber: number) => {
        visible.value = true
        isAdd.value = addNumber
        const params = {
            roleid: row.rolid
        }
        rolesFindrolebyid({ params: params }).then((res) => {
            // res.data.data这里后期，把pg页面的interface导过来就可以定义一个变量了
            formData.form.rolnmcn = res.rolnmcn
            formData.form.rolnmen = res.rolnmen
            // 这里不能赋值，
            formData.form.roltp = res.roltp
            formData.form.roltpnm = res.roltpnm
            formData.form.grpnm =  store.state.lang === 'en' ? res.privgrpnmen : res.privgrpnmcn
            formData.form.privgrpnmcn = res.privgrpnmcn
            formData.form.privgrpnmen = res.privgrpnmen
            formData.form.roldsc = res.roldsc
            formData.form.privgrpid = res.privgrpid
            formData.form.rolid = res.rolid
            formData.form.roltp = res.roltp
            formData.form.state = res.state;
            formData.form.crtby = res.crtby;
            formData.form.crtbynm = res.crtbynm;
            formData.form.crted = res.crted;
            getGPbyType(res.roltp)
        })
        
    }

    const modalTitle = ref<string>('Add Role')
    watch(()=>{
        return isAdd.value
    },(state)=>{
        if ( state === 0) {
            modalTitle.value = 'Edit Role'
        } else if ( state === 1 ) {
            modalTitle.value = 'Add Role'
        } else if ( state === 2 ) {
            modalTitle.value = 'View Role'
        }  
    })
    // 监听当前用户的权限问题
    watch(()=>{
        return store.state.user.usrtp
    },(state)=>{
        if ( state === 10001 ) {
            formData.form.roltpnm = ''
            formData.form.roltp = 0
        } else {
            formData.form.roltpnm = 'Dealer'
            formData.form.roltp = 10002
        } 
    })
    onMounted(() => {
        getGPbyType()   
    })
    return {
        handleEdit,
        handleCancel,
        submitAddPG,
        ruleFormRef,
        grptpChange,
        handleAddPG,
        isAdd,
        modalTitle,
        visible,
        formData,
        grpChange,
        prgOptions
    }
}
export default useAddEdit