import { ref, reactive, nextTick, watch } from 'vue'
import { PGProps, SelectProps } from '@/views/User/types'
import { PGsavegroup, PGupdategroup, PGfindgroupbyid } from '@/API/user'
import { message } from "ant-design-vue"
import { useStore } from 'vuex'
import htmlentities from '@/utils/htmlentities'
const useAddEdit = () => {
    const store = useStore()
    // 增加组的弹窗
    const visible = ref(false);
    // 定义是否添加过
    const isAdd = ref<number>(1)
    const formData = reactive({
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      other: '',
      form: {
        privgrpid: '',
        grpnmcn: '',
        grpnmen: '',
        grptp: store.state.user.usrtp === 10001 ? 0 : 10002,
        grptpnm: store.state.user.usrtp === 10001 ? [] : 'Dealer', // placeholder 需要是数组才显示
        grpdsc: "",
        state: true,
        crtby: '',
        crtbynm: '',
        crted: 0,
      },
      rules: {
        grpnmcn: [
          {
            required: true,
            message: "Please input Chinese name",
            trigger: "blur",
            type:'string',
          },
          { max: 100, message: "Length should be 1 to 100", trigger: "blur" },
          { pattern: /^([a-z]|[A-Z]|[\u4e00-\u9fa5]|[0-9]|\s){0,}$/g, message: "Please enter Chinese，English Letters and  number", trigger: "blur",}
        ],
        grpnmen: [
          {
            required: true,
            message: "Please input English name",
            trigger: "blur",
            type:'string',
          },
          { max: 100, message: "Length should be 1 to 100", trigger: "blur" },
          { pattern: /^([a-z]|[A-Z]|[0-9]|\s){0,}$/g, message: "Please enter English or number", trigger: "blur" }
        ],
        grptpnm: [
          {
            required: true,
            message: "Please select a user group type",
            trigger: "change",
            type:'string',
          },
        ],
        grpdsc: [{ max: 200, message: "Length should be 0 to 200", trigger: "change" }]
      },
    });
    // 显示增加权限组弹窗
    const handleAddPG = () => {
        visible.value = true;
        isAdd.value = 1;
    }
    // 因为下拉不能绑定对象，所以要用change得到label
    const grptpChange = (value: string, option: SelectProps) => {
        formData.form.grptpnm = value;
        formData.form.grptp = option.key;
    }
    // 获取from DOM元素
    const myFromRef = ref()
    const ruleFormRef = (el: HTMLElement | null)=>{
      nextTick(()=>{
        myFromRef.value = el
      })
    }
    // 提交增加组
    const submitAddPG = () => {
        myFromRef.value
        .validate()
        .then(() => {
          visible.value = false;
          const params = {
            grpdsc: htmlentities(formData.form.grpdsc),
            grpnmcn: htmlentities(formData.form.grpnmcn),
            grpnmen: htmlentities(formData.form.grpnmen),
            grptp: formData.form.grptp,
            grptpnm: formData.form.grptpnm,
            privgrpid:formData.form.privgrpid,
            state: formData.form.state,
            crtby: formData.form.crtby,
            crtbynm: formData.form.crtbynm,
            crted: formData.form.crted,
          };
          if (isAdd.value) {
            PGsavegroup(params).then((res) => {
                message.success("Save successfully!");
                console.log(res, "保存成功");
                window.location.reload()
            });
          } else {
            PGupdategroup(params).then((res) => {
                message.success("Save successfully!");
                console.log(res, "编辑成功");
                window.location.reload()
            });
          }
          myFromRef.value.resetFields();
        })
        .catch((error: string) => {
          console.log("error", error);
        });
    };
    // 取消增加权限组弹窗
    const handleCancel = () => {
      visible.value = false;
      myFromRef.value.resetFields();
    };
    // 编辑
    const handleEdit = (row: PGProps, addNumber: number) => {
      visible.value = true;
      isAdd.value = addNumber;
      const params = {
          privgrpid: row.privgrpid
      }
      PGfindgroupbyid({ params: params }).then(res => {
            formData.form.grpnmcn = res.grpnmcn;
            formData.form.grpnmen = res.grpnmen;
            formData.form.grpdsc = res.grpdsc;
            formData.form.grptp = res.grptp;
            formData.form.grptpnm = res.grptpnm;
            formData.form.state = res.state;
            formData.form.privgrpid = res.privgrpid;
            formData.form.crtby = res.crtby;
            formData.form.crtbynm = res.crtbynm;
            formData.form.crted = res.crted;
      })
    };
    const modalTitle = ref<string>('Add User Group')
    watch(()=>{
        return isAdd.value
    },(state)=>{
        if ( state === 0) {
            modalTitle.value = 'Edit User Group'
        } else if ( state === 1 ) {
            modalTitle.value = 'Add User Group'
        } else if ( state === 2 ) {
            modalTitle.value = 'View User Group'
        }  
    })

    // 监听当前用户的权限问题
    watch(()=>{
        return store.state.user.usrtp
    },(state)=>{
        if ( state === 10001 ) {
            formData.form.grptpnm = ''
            formData.form.grptp = 0
            
        } else {
            formData.form.grptpnm = 'Dealer'
            formData.form.grptp = 10002
        } 
    })
    return {
        handleEdit,
        handleCancel,
        submitAddPG,
        ruleFormRef,
        grptpChange,
        handleAddPG,
        isAdd,
        modalTitle,
        visible,
        formData,
    }
}
export default useAddEdit