import { ref, reactive, nextTick, watch, onMounted } from "vue";
import {
  UserProps,
  OrgProps,
  OrgParamsProps,
  OrgDealerProps,
  SelectGlobalProps,
  OrganizationProps,
  EntitySelectProps,
} from "@/views/User/types";
import {
  bizFindbuall,
  userSaveuser,
  userUpdateuser,
  userFinduserbyid,
  checkUserExisted,
  getOrganizationOptions,
} from "@/API/user";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import htmlentities from "@/utils/htmlentities";
import deepClone from "@/utils/deepClone";

interface ValidProps {
  field: string;
  fullField: string;
  message: string;
  required: string;
  trigger: string;
  type: string;
}
const useUserAddEdit = () => {
  const store = useStore();

  // 是否为经销商IT Admin
  const dealerItAdmin = ref(false);
  // 经销商IT Admin默认选中数据
  const defaultDealerItAdminFormData = reactive({
    usrtp: 0,
    usrtpnm: "",
    orgid: "",
    orgnm: "",
  });

  // 增加组的弹窗
  const visible = ref(false);
  let singleData: UserProps;
  // 定义是否添加过
  const isAdd = ref(1);
  let copyUser: string;
  let copyOrg: string;
  // 验证是否注册过这个userNm
  const judgeUsrnm = async (value: string): Promise<boolean> => {
    const existedParams = {
      usrnm: value,
    };
    const res = await checkUserExisted({ params: existedParams });
    return res;
  };
  const validateAsync = async (rule: ValidProps, value: string) => {
    debugger;
    const RegExp = /^([a-z]|[A-Z]|[\u4e00-\u9fa5]|[0-9]|\s){0,}$/;
    if (!value) {
      return Promise.reject("Please input account");
    }
    if (value.length > 8) {
      return Promise.reject("Length should be 1 to 8");
    }
    if (!RegExp.test(value)) {
      return Promise.reject(
        "Please enter Chinese，English Letters and  number"
      );
    }
    if (value !== "" && RegExp.test(value) && copyUser !== value) {
      const res = await judgeUsrnm(value);
      if (res) {
        return Promise.reject("The user name already exists");
      }
    }
    return Promise.resolve("success");
  };
  const valiorgAsync = (rule: ValidProps, value: string) => {
    if (!value && copyOrg !== value) {
      return Promise.reject("Please select an organization type");
    }
    return Promise.resolve("success");
  };

  const formData = reactive({
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
    other: "",
    form: {
      email: "",
      fulnm: "",
      orgid: store.state.user.usrtp === 10001 ? "" : store.state.user.orgid,
      orgnm: visible.value ? "" : [],
      usrnm: "",
      usrtp: 0,
      usrtpnm: "",
      state: true,
      crtby: "",
      crtbynm: "",
      crted: 0,
      entityName: "",
      entityIds: [] as number[],
      dealerItAdmin: false,
      showEntity: false,
    },
    rules: {
      fulnm: [
        { required: true, message: "Please input full name", trigger: "blur" },
        { max: 50, message: "Length should be 1 to 50", trigger: "blur" },
        {
          pattern: /^([a-z]|[A-Z]|[\u4e00-\u9fa5]|[0-9]|\s){0,}$/g,
          message: "Please enter Chinese，English Letters and  number",
          trigger: "blur",
        },
      ],
      usrnm: [{ required: true, trigger: "blur", validator: validateAsync }],
      email: [
        { required: true, message: "Please input Email", trigger: "blur" },
        { max: 100, message: "Length should be 1 to 100", trigger: "blur" },
        {
          pattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/,
          message: "Please enter a valid email address",
          trigger: "blur",
        },
        // { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: 'Please enter a valid email address', trigger: 'blur'}
      ],
      usrtpnm: [
        {
          required: true,
          message: "Please select a user type",
          trigger: "blur",
        },
      ],
      orgnm: [{ trigger: "blur", validator: valiorgAsync }],
    },
  });
  const orgFul = ref<OrganizationProps[]>([]);
  // let orgFulOEM: OrgParamsProps[] = []
  // let orgFulDealer: OrgParamsProps[] = []
  // 获得origintiondeaFinddealerall
  /* const getOrgOEM = () => {
        bizFindbuall().then((res) => {
             orgFulOEM = res
        })
    } */
  // 查找当前用户的org
  /* const findOrg = (arr: OrgParamsProps[]): any => {
        return arr.filter(item => item.id === store.state.user.orgid)
    } */
  // 获取org Dealer下的数据
  /* const getOrgDealer =async () => {
        const res = await allDealerInfos()
        // console.log(store.state.user.usrtp);
        if (store.state.user.usrtp === 10002) {
            orgFulDealer = findOrg(res)
        } else {
            orgFulDealer = res
        }
    } */
  /* const formatOrgFulDealer = () => {
        const arr: OrganizationProps[] = [];
        orgFulDealer.forEach((item) => {
            arr.push({
                entityName: item.nameCN,
                entityCode: item.nameCN,
                entityType: item.nameCN,
                id: item.id,
            })
        })
        return arr
    } */

  // 根据Organization
  const getOrganization = (id: string | number) => {
    const params = {
      parentId: id,
    };
    getOrganizationOptions({ params: params }).then((res) => {
      orgFul.value = res;
    });
  };

  // 判断是否显示Entity
  const isShowEntity = (name: string) => {
    console.log(name, "name.........");
    const data = orgFul.value.find((item) => {
      return item.entityName === name;
    });
    return data?.showEntity;
  };
  // 显示增加权限组弹窗
  const handleAddPG = async () => {
    // if (orgFulOEM.length < 1) getOrgOEM();
    // if (orgFulDealer.length === 1) store.state.lang === "en"?formData.form.orgnm = orgFulDealer[0].nameEN: formData.form.orgnm = orgFulDealer[0].nameCN
    // if (store.state.user.usrtp === 10002) orgFul.value = formatOrgFulDealer()
    // if (store.state.user.usrtp !== 10001) {
    // 去掉这两个，不然生产环境出来的默认不对。Ella
    // formData.form.usrtpnm = 'Dealer'
    // formData.form.usrtp = 10002
    // getOrganization(formData.form.usrtp)
    // }
    if (dealerItAdmin.value) {
      formData.form.usrtp = defaultDealerItAdminFormData.usrtp;
      formData.form.usrtpnm = defaultDealerItAdminFormData.usrtpnm;
      formData.form.orgid = defaultDealerItAdminFormData.orgid;
      formData.form.orgnm = defaultDealerItAdminFormData.orgnm;
    }
    visible.value = true;
    isAdd.value = 1;
  };

  // 因为下拉不能绑定对象，所以要用change得到label
  const grptpChange = (value: string, option: SelectGlobalProps) => {
    formData.form.usrtpnm = value;
    formData.form.usrtp = +option.key;
    // 清空entity选中数据
    formData.form.entityName = "";
    formData.form.entityIds = [];
    // 清空Organization选中数据
    formData.form.orgnm = [];
    formData.form.orgid = "";

    if (["Dealer", "Dealer Group"].includes(value)) {
      formData.form.usrnm = formData.form.usrnm.toUpperCase();
    }

    getOrganization(option.key);

    /* if (formData.form.usrtpnm === 'OEM') {
            orgFul.value = orgFulOEM
        } else {
            orgFul.value = formatOrgFulDealer()
        } */
  };

  // 因为下拉不能绑定对象，所以要用change得到label
  const grpChange = (value: string, option: SelectGlobalProps) => {
    if (value) {
      formData.form.orgnm = value;
      formData.form.orgid = option.key;
    }
    // 清空entity选中数据
    formData.form.entityName = "";
    formData.form.entityIds = [];
  };

  // 因为下拉不能绑定对象，所以要用change得到label
  const entityChange = (value: any, option: EntitySelectProps) => {
    if (value) {
      formData.form.entityIds = value.entityIds;
      formData.form.entityName = value.entityName;
    }
  };

  // 获取from DOM元素
  const myFromRef = ref();
  const ruleFormRef = (el: HTMLElement | null) => {
    nextTick(() => {
      myFromRef.value = el;
    });
  };

  const submitAddPG = async () => {
    myFromRef.value
      .validate()
      .then(() => {
        visible.value = false;
        const params = {
          crtby: formData.form.crtby,
          crtbynm: formData.form.crtbynm,
          crted: formData.form.crted,
          email: formData.form.email,
          fulnm: htmlentities(formData.form.fulnm),
          orgid: formData.form.orgid,
          // "orgnm": formData.form.orgnm,
          state: formData.form.state,
          usrid: isAdd.value ? "" : singleData.usrid,
          usrnm: htmlentities(formData.form.usrnm),
          usrtp: formData.form.usrtp,
          usrtpnm: formData.form.usrtpnm,
          // "entityName": formData.form.entityName,
          entityIds: formData.form.entityIds,
          dealerItAdmin: formData.form.dealerItAdmin,
        };
        if (isAdd.value) {
          userSaveuser(params).then(() => {
            message.success("Save successfully!");
            window.location.reload();
          });
        } else {
          userUpdateuser(params).then((res) => {
            message.success("Save successfully!");
            console.log(res, "编辑成功");
            window.location.reload();
          });
        }
      })
      .catch((error: string) => {
        console.log("error", error);
      });
  };
  // 取消增加权限组弹窗
  const handleCancel = () => {
    visible.value = false;
    myFromRef.value.resetFields();
    formData.form.orgnm = "";
    formData.form.showEntity = false;
  };
  // 编辑
  const handleEdit = (row: UserProps, addNumber: number) => {
    // if (orgFulOEM.length < 1) getOrgOEM()
    // if (orgFulDealer.length < 1) getOrgDealer()
    visible.value = true;
    isAdd.value = addNumber;
    const params = {
      usrid: row.usrid,
    };
    userFinduserbyid({ params: params }).then((res) => {
      formData.form.email = res.email;
      formData.form.fulnm = htmlentities(res.fulnm);
      formData.form.orgid = res.orgid;
      formData.form.orgnm =
        store.state.lang === "en" ? res.orgnmen : res.orgnmcn;
      formData.form.usrnm = htmlentities(res.usrnm);
      copyUser = deepClone(res.usrnm);
      copyOrg = deepClone(
        store.state.lang === "en" ? res.orgnmen : res.orgnmcn
      );
      formData.form.usrtp = res.usrtp;
      formData.form.usrtpnm = res.usrtpnm;
      formData.form.state = res.state;
      formData.form.crtby = res.crtby;
      formData.form.crtbynm = res.crtbynm;
      formData.form.crted = res.crted;
      formData.form.entityName = res.entityName;
      formData.form.entityIds = res.entityIds;
      (formData.form.dealerItAdmin = res.dealerItAdmin),
        (formData.form.entityIds = res.entityIds),
        (formData.form.showEntity = res.showEntity);
      singleData = res;
    });
  };

  const modalTitle = ref<string>("Add User");
  watch(
    () => {
      return isAdd.value;
    },
    (state) => {
      if (state === 0) {
        modalTitle.value = "Edit User";
      } else if (state === 1) {
        modalTitle.value = "Add User";
      } else if (state === 2) {
        modalTitle.value = "View User";
      }
    }
  );
  // 监听当前用户的权限问题
  watch(
    () => {
      return store.state.user.usrtp;
    },
    (state) => {
      if (state === 10001) {
        formData.form.usrtpnm = "";
        formData.form.usrtp = 0;
      } else {
        formData.form.usrtpnm = "Dealer";
        formData.form.usrtp = 10002;
        formData.form.orgid = store.state.user.orgid;
      }
    }
  );

  watch(
    () => {
      return store.state.user.orgid;
    },
    (state) => {
      formData.form.orgid = state;
    }
  );

  onMounted(() => {
    // getOrgDealer()
  });

  // 获取用户信息（判断是否为经销商IT admin）
  const getUserInfo = () => {
    userFinduserbyid({
      params: {
        usrid: store.state.user.usrid,
      },
    }).then((res) => {
      dealerItAdmin.value = res.dealerItAdmin;
      if (res.dealerItAdmin) {
        defaultDealerItAdminFormData.usrtp = res.usrtp;
        defaultDealerItAdminFormData.usrtpnm = res.usrtpnm;
        defaultDealerItAdminFormData.orgid = res.orgid;
        defaultDealerItAdminFormData.orgnm = res.orgnmcn;
      }
    });
  };

  getUserInfo();

  const handleChangeNameToUpperCase = () => {
    if (["Dealer", "Dealer Group"].includes(formData.form.usrtpnm)) {
      formData.form.usrnm = formData.form.usrnm.toUpperCase();
    }
  };

  return {
    dealerItAdmin, // 是否为经销商IT admin
    handleAddPG, // 点击add
    handleEdit, // 点击Edit
    handleCancel, // 取消添加
    submitAddPG, // 确定添加
    ruleFormRef, // 获取元素
    grptpChange, // 下拉选中改变
    grpChange, // 下拉选中改变
    entityChange, // 下拉选中改变
    isAdd,
    modalTitle,
    visible,
    formData,
    orgFul,
    isShowEntity,
    handleChangeNameToUpperCase,
  };
};
export default useUserAddEdit;
