export default {
  search: "搜索",
  save: "保存成功",
  user: {
    navTip: "系统管理",
    navRole: "角色管理",
    navUser: "用户管理",
    name: "用户名",
    fulnmm: "全名",
    userType: "类型",
    password: "密码",
    filterName: "过滤用户名",
    filterpgName: "过滤权限组",
    filterRoleName: "过滤角色",
    filterType: "请选择类型",
    edit: "编辑",
    deactivate: "软删除",
    setting: "设置",
    cnName: "中文名字",
    enName: "英文名字",
    type: "类型",
    description: "描述",
    operation: "操作",
    menuName: "菜单&操作",
    API: "接口",
    grg: "权限组",
    org: "组织",
    orgFul: "组织",
    email: "邮箱",
    findUser: "查找一个用户",
    findRole: "查找一个角色",
    importUser: "导入设置",
    pleaseInput: "请输入",
    inputNameEn: "请输入英文名称",
    inputNameCn: "请输入中文名称",
    inputRoleCode: "请输入角色编码",
    inputDescription: "请输入描述",
    inputName: "请输入用户名",
    inputFullName: "请输入全名",
    inputEmailName: "请输入邮箱",
    pgName: "权限组名称",
    delConfirm: "您确定修改该权限组的状态吗?",
    roleName: "角色",
    roleCode: "角色编码",
    roleType: "角色类型",
    names: "名称",
    code: "编码",
    approvalChianSetting: "Approval Chain Setting",
    programApprovalChain: "Program Approval Chain",
    payoutApprovalChain: "Payout Approval Chain",
    configuration: "Configuration",
    sampleCheckRule: "Sample Check Rule",
    entity: "Entity",
    NGMSRPOverride: "NST Group MSRP Override",
    IMPACTMSRP: "IMPACT MSRP Override",
  },
  program: {
    addTable: "添加新的表格",
    addRow: "添加新的行",
    delete: "删除",
    upload: "上传",
    download: "下载",
    refresh: "刷新",
  },
  retailValidation: {
    rv: "零售校验",
    dealer: "新车零售发票上传",
    controlling: "controlling overview",
    sicco: "SICCO overview",
    sipo: "Retail Information Overview",
  },
  placeholder: "请输入",
  retailClaim: {
    dealer: "Dealer Claim",
    claim: "SALES INCENTIVE CLAIM",
    RT: "F&L Retail CLAIM",
    RTClaimDetail: "F&L CLAIM detail",
    WS: "AFC WS CLAIM",
    WSClaimDetail: "AFC WS CLAIM detail",
    query: "Claim Enquiry",
    VINBased: "VIN Based Enquiry",
    EnquiryProgram: "Enquiry Program",
  },
  checking: {
    plan: "Checking Plan",
    checking: "Checking",
    sourceDataManagement: "Source Data Management",
    AG: "AG Management",
    TA: "Sign TA Management",
    TDTA: "TDTA Management",
    sicco: "SICCO Checking",
    spotChecking: "Spot Checking",
    spotCheckingControlling: "Spot checking rewiew & confirm",
    spotCheckingPendingApproval: "Pending Approval",
    approvalReview: "Approval Review",
    programDetail: "Program Detail",
    vinDetail: "VIN Detail",
    spotCheckOverview: "Spot Check Overview",
    spotCheckConfirm: "Spot Check Confirm",
    allClaimsReview: "All Claims Review",
    payoutPreparation: "Payout Preparation",
    dealer: "Dealer Communication",
    sipo: "Incentive Checking Communication",
  },
  payment: {
    payment: "支付",
    paymentMBAFC: "F&L Payment Management - RT",
    paymentApprove: "Mgmt. Payment Approve",
    paymentBuManagement: "BU payment Management",
    buSiPayout: "Bu SI Payout Application",
    paymentDealerNo: "Delear No. Overview",
    paymentBuApprove: "BU Payment Management",
    createPayoutCode: "Create payout code",
    paymentSalesManagement: "Sales Payment Management",
    dcnTemplateSet: "DCN Template Set",
    vendorCode: "Vendor Code Overview",
    upload: "Upload",
    vehicleList: "Vehicle List",
    paymentDealerStatus: "Dealer Readiness Setting",
    paymentBBACSales: "BBAC Payment Management",
    voucherAttachment: "Voucher Attachment Set",
    BreakdownDetail: "经销商付款准备文件明细",
    dealerBreakdownReport: "Dealer Breakdown Report",
    Breakdown: "销售激励付款查询",
  },
  closing: {
    home: "Monthly Closing",
    configuration: "Configuration",
    setPMT: "Set PMT Closing Rule",
  },
  forecast: {
    home: "Monthly Forecast",
    configuration: "Configuration",
    setPMT: "Set PMT Forecast Rule",
  },
  taskCenter: {
    systemSetting: "System Setting",
    taskCenter: "Task Center",
    taskJob: "Task Job",
    packageTask: "Package Task",
  },
  planning: {
    planning: "Planning",
    homepage: "Homepage",
  },
  delay: {
    claimDelayRule: "Claim Delay Rule",
    dealerDelayClaim: "Dealer Delay Claim",
    sipoApprove: "SIPO Approve",
    regionApprove: "Region Approve",
  },
  communication: {
    communication: "沟通",
    appeal: "销售激励项目审核结果沟通",
    history: "查询历史沟通记录",
  },
  salesmeasure: {
    detail: "项目明细",
    batchname: "政策名称",
    releasedate: "发布时间",
    programcode: "项目编码",
    programnamecn: "项目名称(中文)",
    programnameen: "项目名称(英文)",
    programname: "项目名称",
    reset: "清空",
    search: "搜索",
    download: "下载文件",
    selectprogram: "选择需要下载的项目",
    selectattch: "选择需要下载的项目附件",
    salesmeasurelist: "客户政策销售顾问速览表",
    smlfilename: "文件名称",
    period: "项目期间/提交期间",
    attachmentname: "文件名称",
    pleaseSelect: "请选择!",
    bu: "业务部门",
    expand: "展开",
    releaseperiod: "发布期间",
    collapse: "折叠",
    programcount: "项目数量",
  },
  dealerData: {
    dealerCode: "经销商编号",
    dealerNameEn: "经销商名称(EN)",
    dealerNameCn: "经销商名称(CN)",
    search: "查询",
    reset: "重置",
    status: "状态",
    gsCode: "GS编号",
    dealerGroup: "经销商集团",
    provinceEn: "省(EN)",
    cityEn: "城市(EN)",
    operation: "操作",
    edit: "编辑",
    view: "查看",
  },
  dealerDataEdit: {
    dealerCode: "经销商编号",
    dealerNameEn: "经销商名称(EN)",
    dealerNameCn: "经销商名称(CN)",
    search: "查询",
    reset: "重置",
    status: "状态",
    gsCode: "GS编号",
    dealerGroup: "经销商集团",
    provinceEn: "省(EN)",
    cityEn: "城市(EN)",
    operation: "操作",
    edit: "编辑",
    view: "查看",
    active: "激活",
    save: "保存",
    baseInfo: "基础信息",
    legalNameCn: "法定名称(CN)",
    legalNameEn: "法定名称(EN)",
    additionalNameCn: "附加名称(CN)",
    additionalNameEn: "附加名称(EN)",
    provinceCn: "省(CN)",
    provinceEn2: "省(EN)",
    cityCn: "城市(CN)",
    cityEn2: "城市(EN)",
    addressCn: "地址(CN)",
    addressEn: "地址(EN)",
    zipCode: "ZIP编码",
    format: "格式",
    contactEmail: "联系邮箱",
    fixPhone: "固定电话",
    dealerGroup2: "经销商集团",
    swtCode: "SWT编码",
    vendorCode: "Vendor编码",
    mbafcCode: "MBAFC编码",
    languageValue: "语言",
    dosCode: "DOS编码",
    isGovernmentRelated: "是否与政府相关",
    salesInfo: "销售信息",
    dealerType: "经销商类型",
    yes: "是",
    no: "否",
    businessUnit: "业务单元",
    changeHistory: "修改历史",
    changeDate: "修改时间",
    changeBefore: "修改前",
    changeAfter: "修改后",
    operationUser: "操作人",
  },
  dealerVINBasedEnquiry: {
    searchConditions: "筛选条件",
    vinNO: "车架号",
    vehicleClassification: "车型分类",
    vehicleCode: "车型编码",
    vehicleName: "车型名称",
    modelYear: "年款",
    wholesaleSource: "批发来源",
    wholesaleDate: "批发日期",
    retailDate: "零售日期",
    dealerCode: "经销商编码",
    dealerNameCN: "经销商名称（CN）",
    dealerNameEN: "经销商名称（EN）",
    pleaseInput: "请输入...",
    pleaseSelect: "请选择",
    exportVehicleCompatibility: "导出车辆兼容性",
    compatible: "兼容",
    incompatible: "不兼容",
    beginTime: "开始时间",
    endTime: "结束时间",
    search: "查询",
    reset: "重置",
  },
  claimEnquiry: {
    dealerCode: "经销商编码",
    dealerSwtCode: "SWT Code",
    vehicleName: "车型名称",
    vinNO: "车架号",
    projectPeriod: "项目期间",
    dealerNameCN: "经销商名称（中文）",
    modelYear: "年款",
    appealStatus: "申诉状态",
    projectNO: "项目编号",
    projectType: "项目类型",
    optionName: "选项名称",
    paymentRound: "支付轮次",
    checkResults: "检查结果",
    estimatedAmountOfCustomerPolicy: "客户政策预估金额",
    actualAmount: "实际金额",
    createTime: "创建时间",
    status: "状态",
    operation: "操作",
    search: "查询",
    reset: "重置",
    export: "导出",
    cannotWithdraw: "无法撤回",
    ok: "确定",
    cancel: "取消",
    withdrawSuccessful: "撤销成功",
    close: "关闭",
    applyDeferredWithdraw: "申请延期撤回",
    retailDateStr: "零售时间",
    programPeriod: "项目期间",
    bu: "业务单元",
    region: "经销商所在区域",
    automaticDeduction: "是否自动扣款",
  },
  retailValidationSipo: {
    searchConditions: "筛选条件",
    exportQueryResult: "导出查询结果",
    search: "查询",
    reset: "重置",
    more: "更多",
    vin: "车架号",
    model: "车型",
    brand: "品牌",
    dealerNameCN: "经销商中文名称",
    dealerCodeCofico: "经销商代码 (cofico)",
    dealerCodeSWT: "经销商代码 (SWT)",
    region: "区域",
    customerName: "客户名称",
    retailType: "零售类型",
    retailDate: "零售日期",
    specialUsage: "特殊车辆用途",
    submissionStatus: "提交状态",
    eligibilityStatus: "激励适用性",
    failureReason: "失败原因",
    file: "文件",
    operation: "操作",
    vehicleSort: "车辆类别",
    commissionNo: "生产编号",
    typeClass: "车型分类",
    modelYear: "年款",
    entity: "批发来源",
    powertrain: "动力类型",
    dealerSort: "经销商类别",
    dateSort: "日期类别",
    invoiceDate: "发票日期",
    registrationDate: "上牌日期",
    checkResultSort: "审核结果类别",
    collapse: "收起",
    vinBatchEnquiry: "车架号批量查询",
    vinList: "车架号列表",
    commissionNoBatchEnquiry: "生产编号批量查询",
    commissionNoList: "生产编号列表",
    clearAll: "清除",
    confirm: "确定",
    failureReasonDetails: "失败原因查看",
    feedbackTime: "反馈时间",
    view: "查看",
    invoice: "发票",
    license: "行驶证",
    details: "详情",
    retailDataDetails: "零售数据详细信息",
    basicInformtion: "基本信息",
    dealerInformtion: "经销商信息",
    vehicleRetailHistory: "车辆零售历史信息",
    operationRecord: "操作记录",
    retailStatus: "车辆零售状态",
    vehicleUsage: "车辆用途",
    role: "操作角色",
    action: "动作",
    operationTime: "操作时间",
    pleaseInput: "请输入",
    beginTime: "开始时间",
    endTime: "结束时间",
  },
  ssscOrder: {
    brand: "品牌/车型",
    updatedTime: " 更新时间",
    fin: "车架号(FIN)",
    vin: "车架号",
    programCode: "项目编号",
    packageValidFrom: "礼包有效开始时间",
    typeClass: "类型",
    status: "状态",
    description: "描述",
    result: "处理结果",
    errorMessage: "错误原因",
    startSystemClaimDate: "处理时间",
    claimID: "申请编号",
    createdTime: "创建时间",
    activateDate: "激活时间",
    packageName: "礼包名称",
    dealerCode: "经销商编号",
    outletId: "经销商GS编号",
    dealerName: "经销商名称(中文)",
    productCode: "产品编码",
    wholesalePrice: "金额",
    iconContractNumber: "合同号",
    reset: "重置",
    search: "查询",
    export: "导出",
    cancel: "取消",
    confirm: "确认",
    programCodeMapping: "项目编号匹配",
    startSystemClaim: "开始处理",
    pleaseInput: "请输入...",
    pleaseSelect: "请选择",
    beginTime: "开始日期",
    endTime: "结束日期",
  },
};
