import { RouteRecordRaw } from 'vue-router'

export const claimRouters: Array<RouteRecordRaw> = [
    {
        path: '/claim',
        name: 'Claim',
        component: () => import('@/views/Claim/RT/index.vue'),
    },
    {
        path: '/claim/claimDetail',
        name: 'claimDetail',
        component: () => import('@/views/Claim/RT/ClaimDetail/index.vue')
    },
    {
        path: '/claim/Ws',
        name: 'claimWs',
        component: () => import('@/views/Claim/WS/index.vue')
    },
    {
        path: '/claim/Ws/wsDetail',
        name: 'claimWs Detail',
        component: () => import('@/views/Claim/WS/ClaimDetail/index.vue')
    },
    {
        path: '/parametersetup',
        name: 'Parameter Set Up',
        component: () => import ('@/views/Claim/ParameterSetUp/index.vue')
    },
    {
        path: '/documentSample',
        name: 'Document Sample',
        component: () => import ('@/views/Claim/DocumentSample/index.vue')
    },
    {
        path: '/claim/Dealer',
        name: 'Sales Incentive Claim',
        component: () => import('@/views/Claim/DealerClaim/DealerList/index.vue')
    },
    {
        path: '/claim/DealerProgramCategory',
        name: 'DealerProgramCategory',
        component: () => import('@/views/Claim/DealerClaim/DealerProgramCategory/index.vue')
    },
    {
        path: '/claim/DealerVINList',
        name: 'DealerVINList',
        component: () => import('@/views/Claim/DealerClaim/DealerVINList/index.vue')
    },
    // 搜索yangyi
    {
        path: '/claim/DealerQuery',
        name: 'DealerQuery',
        component: () => import('@/views/Claim/DealerClaim/DealerQuery/index.vue')
    },
    {
        path: '/claim/DealerVINBasedEnquiry',
        name: 'DealerVINBasedEnquiry',
        component: () => import('@/views/Claim/DealerClaim/DealerVINBasedEnquiry/index.vue')
    },
    // enquiry program
    {
        path: '/claim/EnquiryProgram',
        name: 'EnquiryProgram',
        component: () => import('@/views/Claim/EnquiryProgram/index.vue')
    },
    //dealyDealerClaim
    {
        path: '/claim/DelayDealerClaim',
        name: 'DelayDealerList',
        component: () => import('@/views/Claim/DelayDealerClaim/DealerList/index.vue')
    },
    {
        path: '/claim/DelayDealerProgramCategory',
        name: 'DelayDealerProgramCategory',
        component: () => import('@/views/Claim/DelayDealerClaim/DealyDealerProgramCategory/index.vue')
    },
    {
        path: '/claim/SSSCOrder',
        name: 'SSSC Order',
        component: () => import('@/views/Claim/SsscOrder/index.vue')
    },
]